import React, { useEffect, useState, useRef } from 'react';
import store, { actions, getters } from 'redux/store';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';

import HeaderBar from 'components/header-bar';
import OrderItemList from 'components/order-item-list';
import OrderCancel from 'components/order-cancel/index.jsx';
import DiscountCard from 'components/cart/discount-card';

import WebFn, { callAppFunc } from 'utils/jsbridge/index.js';
import { useAPI } from 'utils/api';
import { showLoading, dismissLoading } from 'utils/loading';
import { setPurchase } from 'utils/_ga';
import { setMetaPurchase } from 'utils/_meta';
import {
  setAppsFlyerPurchase,
  setAppsFlyerFirstPurchase,
} from 'utils/_appsflyer';

import IconOrderWait from '../../../images/icons/i-order-wait.png';
import IconOrderFin from '../../../images/icons/i-order-fin.png';
import IconOrderCancel from '../../../images/icons/i-order-cancel.png';
import IconDiscountNotice from '../../../images/icons/i-notice.svg';

// 使用cookie
import { fetchCookie, deleteCookie } from 'utils/cookie';

let isFromPayment = false;

const linkStyle = {
  display: 'inline-block',
  width: 'auto',
  textAlign: 'center',
  backgroundColor: '#5FD2DA',
  borderRadius: '20px',
  color: '#fff',
  marginTop: '12px',
  padding: '4px 12px',
  textDecoration: 'none',
  boxSizing: 'border-box',
};

const OrderType = {
  FINISHED: 30, //完成
  CANCEL: 40, // 取消
  DISPATCH: 20, //配送中
  TEN: 10, // 狀態10
};

const HeaderBarWrapper = styled.div`
  width: 100%;
  z-index: 1;
  position: fixed;
  top: 0;
`;

const Page = styled.div`
  padding-top: 70px;
  position: relative;
  background-color: #fff;
  height: 100%;
`;

const Sheet = styled.div`
  background-color: #fff;
  padding: 20px 18px 0 16px;
  margin-bottom: 3px;
`;

const Total = styled.div`
  flex-grow: 1;
  background-color: #f2f2f2;
  padding: 27px 18px 11px 16px;
`;

const Action = styled.div`
  padding: 24px 18px 24px 16px;
  background-color: #fff;

  button.mb-16 {
    margin-bottom: 16px;
  }
`;

const QuestionnaireWrapper = styled.div`
  background-color: #d7f4f699;
  padding: 16px;
`;

const Row = styled.div`
  display: grid;
  padding-bottom: 14px;
  grid-template-columns: auto 1fr;

  &.mapframe {
    display: inherit;
    text-align: end;
  }

  .title {
    font-size: 16px;
    line-height: 23px;
    color: #333333;
  }

  .content {
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    color: #333333;
    text-align: end;
    &.content-title {
      text-align: start;
    }
  }

  .map {
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    text-decoration: underline;
    color: #00f;
  }
`;

const OrderInfo = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 36px;
    height: 36px;
  }
`;

const OrderInfoFrame = styled.div`
  margin-left: 16px;
  p.info-status {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: #3b3516;
  }
  p.info-time {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #000000;
  }
`;
const RowLabel = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
`;
const RowValue = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  color: #333333;
  text-align: end;
  ${(props) =>
    props.textAlign &&
    css`
      text-align: start;
    `}
`;
const DiscountNoticeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  position: relative;

  > img {
    cursor: pointer;
  }
`;

const OrderDetail = ({ location, transNo }) => {
  const api = useAPI();

  const orderDetail = useSelector((state) => getters.getOrderDetail(state));
  const hashAccount = useSelector((state) => getters.getHashAccount(state));

  const [amount, setAmount] = useState(0);
  const [isBackMain, setBackMain] = useState(false);
  const [showPage, setShowPage] = useState(false);
  const [cancelOption, setCancelOption] = useState([]);
  const [showOrderCancel, setOrderCancel] = useState(false);
  const [rateData, setRateData] = useState(null);
  const [orderCount, setOrderCount] = useState(null);
  const [isDiscountCardShow, setIsDiscountCardShow] = useState(false);

  // 倒數顯示時間
  const [displayTime, setDisplayTime] = useState('');
  // interval id
  const [intervalId, setIntervalId] = useState(null);

  const tmpOrderDetailData = useRef({});

  var querySource = '';

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const goHelp = () => {
    navigate('/order/help', {
      replace: false,
    });
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const goInfo = () => {
    navigate('/order/info/order', {
      replace: false,
    });
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const goCart = () => {
    api
      .reOrder(transNo)
      .then((res) => {
        dismissLoading();
        if (!res) {
          return;
        }

        navigate('/cart', {
          state: { page: '' },
          replace: true,
        });
      })
      .catch((error) => {
        dismissLoading();
        console.log('reorder exception =', error);
      });
  };

  const getCancelOption = () => {
    api
      .getCustomerCancel()
      .then((res) => {
        setCancelOption(res);
      })
      .catch();
  };

  const getOrderDetail = () => {
    WebFn({ callback: jsBridgeCallback });
    var source = '';
    if (location.state != null) {
      source = location.state.query_source;
    } else {
      source = querySource != null ? querySource : undefined;
    }
    api
      .getOrderDetail(transNo, source)
      .then((res) => {
        dismissLoading();
        if (!res) {
          return;
        }
        store.dispatch(actions.setOrderDetail(res));

        tmpOrderDetailData.current = res;

        var total = 0;
        var discount = 0;
        for (const i of res.products) {
          total += i.subtotal;
        }
        for (const i of res.refund_products) {
          discount += i.subtotal;
        }

        setAmount(total - discount);
        if (isFromPayment) {
          isFromPayment = false; //防止第二次觸發
          const attributionToken = fetchCookie('attributionToken');

          setPurchase(hashAccount, res, attributionToken);

          // 清除cookie
          deleteCookie('attributionToken');

          // appsflyer
          setAppsFlyerPurchase(res);

          // 詢問是否為新用戶
          callAppFunc('getIsNewUser', {});
          setMetaPurchase(res);
        }

        // 顯示時間字樣
        startDeliveryTimer(res);

        setShowPage(true);
      })
      .catch((error) => {
        dismissLoading();
        console.log('get order detail exception =', error);
      });
  };
  const checkData = () => {
    //檢查RateData是否都為false
    if (rateData) {
      for (const [key, value] of Object.entries(rateData)) {
        if (value) {
          return false;
        }
      }
      return true;
    }
  };

  const jsBridgeCallback = (strJson) => {
    const dataJson = JSON.parse(strJson);

    if (dataJson.fnName === 'sendRateData') {
      setRateData(dataJson.params.data);
    }
    if (dataJson.fnName === 'sendSuccessedOrderCount') {
      setOrderCount(dataJson.params.count);
    }
    if (dataJson.fnName === 'sendIsNewUser') {
      let isNewUser = dataJson.params.data === 'true';
      if (isNewUser) {
        setAppsFlyerFirstPurchase(tmpOrderDetailData.current);
      }
    }
  };

  // 外送時間倒數器
  function startDeliveryTimer(order) {
    if (setIntervalId !== null) {
      clearInterval(intervalId);
      setIntervalId(null);
    }

    setDisplayTimeFunction(order);

    let timer = order.estimated_delivery_time - Math.floor(Date.now() / 1000);

    if (order.estimated_delivery_time != 0) {
      const id = setInterval(function () {
        setDisplayTimeFunction(order);

        if (--timer < 0) {
          clearInterval(intervalId);
        }
      }, 1000);

      setIntervalId(id);
    } else {
      if (intervalId != null) clearInterval(intervalId);
      setIntervalId(null);
    }
  }

  function setDisplayTimeFunction(order) {
    const seconds =
      order.estimated_delivery_time - Math.floor(Date.now() / 1000);
    setDisplayTime(order.delivery_datetime);
    if (order.estimated_delivery_time != 0) {
      if (seconds <= 0) {
        setDisplayTime('--');
      }
    }
  }
  useEffect(() => {
    if (rateData && orderCount) {
      if (orderCount >= 5) {
        callAppFunc('saveSuccessedOrderCount', { count: 0 });
        let checkResult = checkData();
        if (checkResult) {
          callAppFunc('openRateView', {});
        }
        callAppFunc('defaultRateData');
      }
    }
  }, [rateData, orderCount]);

  useEffect(() => {
    showLoading();
    // set js-bridge callback
    WebFn({ callback: jsBridgeCallback });

    const params = new URLSearchParams(location.search);
    const from = params.get('from');
    const source = params.get('query_source');
    querySource = source != null ? source : undefined;

    isFromPayment = false;

    if (from != null) {
      if (from === 'payment') {
        isFromPayment = true;
        callAppFunc('getRateData', {});
        callAppFunc('getSuccessedOrderCount', {});
      }

      localStorage.removeItem('TmpCartData');
      setTimeout(getOrderDetail, 60_000);
      setBackMain(true);
    } else {
      setBackMain(false);
    }

    getOrderDetail();
    getCancelOption();
  }, []);

  return (
    <>
      <HeaderBarWrapper>
        <HeaderBar
          title={`訂單號:${orderDetail.order_no ?? ''}`}
          goBack={() => {
            if (isBackMain) {
              callAppFunc('backMain', {});
            } else {
              callAppFunc('backHome', {});
            }
          }}
          showorderCancel={orderDetail.can_cancel}
          setOrderCanacelDialog={() => setOrderCancel(true)}
        />
      </HeaderBarWrapper>
      {showPage && (
        <Page>
          {orderDetail.survey_url && (
            <QuestionnaireWrapper>
              <div>
                為了讓家樂福做得更好，請花三分鐘，請告訴我們您在家樂福線上購物的購物體驗
              </div>
              <a
                href={orderDetail.survey_url}
                style={linkStyle}
                target="_blank"
              >
                開始做答
              </a>
            </QuestionnaireWrapper>
          )}
          <Sheet>
            <Row>
              <OrderInfo>
                <img
                  src={
                    orderDetail.ecorder_status_id == OrderType.CANCEL
                      ? IconOrderCancel
                      : orderDetail.ecorder_status_id == OrderType.FINISHED
                      ? IconOrderFin
                      : IconOrderWait
                  }
                />
                <OrderInfoFrame className="info-frame">
                  <p className="info-status">{orderDetail.ship_status}</p>
                  <p className="info-time">
                    {orderDetail.ecorder_status_id == OrderType.CANCEL
                      ? '取消：'
                      : orderDetail.ecorder_status_id == OrderType.FINISHED
                      ? '完成：'
                      : '預計：'}
                    {`${displayTime}`}
                  </p>
                </OrderInfoFrame>
              </OrderInfo>
            </Row>
            {orderDetail.affiliation && (
              <Row>
                <RowLabel>{'來源'}</RowLabel>
                <RowValue>{orderDetail.affiliation}</RowValue>
              </Row>
            )}
            <Row>
              <RowLabel>
                {orderDetail.shipping_model_id === '1' ? '配送店' : '取貨店'}
              </RowLabel>
              <RowValue>
                {orderDetail.from_store_name ?? ''}{' '}
                {orderDetail.shipping_model_id === '1' ? '外送' : '到店取貨'}
              </RowValue>
            </Row>
            <Row>
              <RowLabel>
                {orderDetail.shipping_model_id === '1'
                  ? '送貨地址'
                  : '取貨地址'}
              </RowLabel>
              <RowValue>
                {orderDetail.shipping_model_id === '1'
                  ? `${orderDetail.zipcode ?? ''}${
                      orderDetail.shipping_address ?? ''
                    }`
                  : orderDetail.to_store_address}
              </RowValue>
            </Row>

            {orderDetail.order_tracking_url !== '' && (
              <Row className="mapframe">
                <div
                  className="map"
                  onClick={() => {
                    confirm(
                      `即將幫您導向物流配合廠商${orderDetail.logistics_name}網頁`
                    ).then(() => {
                      callAppFunc('openNativeBrowser', {
                        url: orderDetail.order_tracking_url,
                      });
                    });
                  }}
                >
                  即時配送狀態
                </div>
              </Row>
            )}
            <OrderItemList
              orderItems={orderDetail.products ?? []}
              refundItems={orderDetail.refund_products ?? []}
            />
            <Row>
              <RowLabel>小計</RowLabel>
              <RowValue>${amount}</RowValue>
            </Row>
            {orderDetail.shipping_model_id === '1' && (
              <Row>
                <RowLabel>外送服務費</RowLabel>
                <RowValue>${orderDetail.shipping_fee}</RowValue>
              </Row>
            )}
            {orderDetail.promotion_discount_amount === undefined ||
            orderDetail.promotion_discount_amount === 0 ? undefined : (
              <Row>
                <RowLabel>
                  折扣
                  <DiscountNoticeWrapper>
                    <img
                      src={IconDiscountNotice}
                      onClick={() => setIsDiscountCardShow(true)}
                    />
                  </DiscountNoticeWrapper>
                </RowLabel>
                <RowValue>-${orderDetail.promotion_discount_amount}</RowValue>
              </Row>
            )}
            {orderDetail.clpe_deduct_amount === 0 ? undefined : (
              <Row>
                <RowLabel>紅利折抵</RowLabel>
                <RowValue>-${orderDetail.clpe_deduct_amount}</RowValue>
              </Row>
            )}
            {orderDetail.overpay_amount > 0 && (
              <Row>
                <RowLabel>秤重商品溢收退款</RowLabel>
                <RowValue>-${orderDetail.overpay_amount}</RowValue>
              </Row>
            )}
          </Sheet>
          <Total>
            <Row>
              <RowValue textAlign={true}>總計</RowValue>
              <RowValue>${orderDetail.total_amount ?? ''}</RowValue>
            </Row>
          </Total>
          <Action>
            {orderDetail.ecorder_status_id == OrderType.CANCEL ||
            orderDetail.ecorder_status_id == OrderType.FINISHED ? (
              <button className="mb-16" onClick={goCart}>
                重新下單
              </button>
            ) : orderDetail.can_cancel ? (
              <button
                className="mb-16"
                onClick={() => {
                  setOrderCancel(true);
                }}
              >
                取消訂單
              </button>
            ) : (
              <button
                style={{ borderColor: '#D0D0D0', color: '#D0D0D0' }}
                className="outlined mb-16"
              >
                取消訂單
              </button>
            )}
            <button className="outlined mb-16" onClick={goInfo}>
              其他資訊
            </button>
            <button className="outlined" onClick={goHelp}>
              取得協助
            </button>
          </Action>
          <OrderCancel
            order_no={orderDetail.order_no}
            show={showOrderCancel}
            setOrderCancel={() => setOrderCancel(false)}
            cancel_option={cancelOption}
            goBack={() => {
              if (isBackMain) {
                callAppFunc('backMain', {});
              } else {
                callAppFunc('backHome', {});
              }
            }}
          />
          <DiscountCard
            isShow={isDiscountCardShow}
            fromDetail={true}
            promotionInfo={orderDetail.promotionInfo}
            setIsShow={setIsDiscountCardShow}
          />
        </Page>
      )}
    </>
  );
};

export default OrderDetail;
